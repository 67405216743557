$('document').ready(function () {
  $('.m-carousel-blog').each(function () {
    const carouselContainer = $(this)
    const carouselInner = $(this).find('[data-ui-el="nfb-carousel-track"]')
    const entries = []
    const lang = carouselContainer.attr('data-lang')
    let slug = carouselContainer.attr('data-slug-en')

    // Defain URL based on `lang`
    let blog = 'blog'
    let nfb = 'nfb'
    if (lang === 'fr') {
      blog = 'blogue'
      nfb = 'onf'
      slug = carouselContainer.attr('data-slug-fr')
    }

    const domain = `https://${blog}.${nfb}.ca`
    let url = `${domain}/${slug}/feed`
    if (!slug) url = `${domain}/feed`

    // Get the XML feed
    $.ajax(url, {
      accepts: {
        xml: 'application/rss+xml'
      },
      dataType: 'xml',
      success: function (data) {
        $(data).find('item').each(function (index, value) {
          if (index > 11) {
            return false
          }
          // Parsing the relevant nodes from the feed and passing them to carouselEntry to construct the DOM element
          const entry = carouselEntry(
            $(this).find('link').text(),
            $(this).find('enclosure').last().attr('url'),
            $(this).find('title').text(),
            $(this).find('dc\\:creator').text(),
            $(this).find('img').attr('src'),
            lang
          )
          entries.push(entry)
        })

        carouselInner.append(...entries)
        carouselInner.show()
        const carouselsManager = new CarouselsManager({ carouselSelector: '[data-ui-el="nfb-blog-carousel"]' })
        const cardManager = new CardManager({ selector: '[data-ui-el="nfb-blog-carousel"] [data-ui-el="nfb-card"]', autoInit: false })
        carouselsManager.init()
        cardManager.init()
      }
    })
  })
})

function carouselEntry (link, image, title, author, avatar) {
  return `
    <li class="nfb-carousel__card" data-ui-el="nfb-carousel-card" role="group" aria-roledescription="slide" data-gtm-nfb_item_list_name="${title}">
    <div class="nfb-card" data-ui-el="nfb-card">
      <div class="nfb-card__container">
        <div
          class="nfb-card__thumbnail-container"
          data-ui-el="nfb-card-thumbnail-container"
        >
          <img
            class="nfb-card__thumbnail"
            src=""
            data-ui-el="card-image"
            data-src-lazy="${image}"
            alt="${title}"
          />
        </div>
        <div class="nfb-card__content">
          <div class="nfb-card__header">
            <a
              class="nfb-card__title nfb-card__title--blog"
              data-ui-el="nfb-card-link"
              href="${link}"
              data-gtm-event="nfb_select_work"
              data-gtm-item_name="${title}"
              data-gtm-nfb_item_list_id="nfb_blog"
              data-gtm-item_category="blog-post"
              data-gtm-nfb_list_type="carousel"
              data-gtm-nfb_list_category="articles"
            >
              <text class="_nfb--has-clamped-text">${title}</text>
            </a>
            <div class="nfb-card__legend-container">
              <div class="nfb-card__author">
                <img class="nfb-card__author-avatar" src="${avatar}" />
                <div class="nfb-card__author-name _nfb--has-clamped-text">${author}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
  `
}
